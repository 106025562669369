/* MAIN CONTAINERS */ 
.overflow-container {
    overflow-x: auto;
}

.game-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: none;
    height: 532px;
}

.game-container, .dashboard {
    width: 536px;
    margin-top: none;
    border: 4px solid black;
    border-top:none;
    display: flex;
    color: white;
}

.dashboard {
    height: 50px;
    justify-content: space-around;
    background-color: white;
    color: black;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
}

.reset {
    font-size: 24px;
    height: 36px;
}

.reset:hover {
    color: white;
    background-color: black;
    border-style: inset;
}
/* ********** */

/* GRID */
.grid, .grid-row {
    display: flex;
}

.grid {
    flex-direction: column;
    width:528px;
}

.grid-row {
    border-bottom: 4px solid black;
}

.grid-row:last-child {
    border-bottom: none;
}

.grid-block {
    width: 133px;
    height: 129px;
    border-right: 4px solid black;
    background-color: white;
}

.grid-block:last-child {
    width: 129px;
    border-right: none;
}
/* ********** */


/*POSITIONS*/

.pos-1-1 {
    transform: translate(0, 0);
}

.pos-1-2 {
    transform: translate(133px, 0);
}

.pos-1-3 {
    transform: translate(266px, 0);
}

.pos-1-4 {
    transform: translate(399px, 0);
}

.pos-2-1 {
    transform: translate(0, 133px);
}

.pos-2-2 {
    transform: translate(133px, 133px);
}

.pos-2-3 {
    transform: translate(266px, 133px);
}

.pos-2-4 {
    transform: translate(399px, 133px);
}

.pos-3-1 {
    transform: translate(0, 266px);
}

.pos-3-2 {
    transform: translate(133px, 266px);
}

.pos-3-3 {
    transform: translate(266px, 266px);
}

.pos-3-4 {
    transform: translate(399px, 266px);
}

.pos-4-1 {
    transform: translate(0, 399px);
}

.pos-4-2 {
    transform: translate(133px, 399px);
}

.pos-4-3 {
    transform: translate(266px, 399px);
}

.pos-4-4 {
    transform: translate(399px, 399px);
}
/* ********** */

/* TILES */
.tile-container {
    position: absolute;
    width: 532px;
    height: 532px;
}

@keyframes tile-pop {
    50% {transform: scale(1.1);}
    100% {transform: scale(1);}
}

@keyframes tile-grow {
    0% {transform: scale(0.1);}
    100% {transform: scale(1);}
}

.tile {
    line-height: 129px;
    font-size: 48px;
    text-align: center;
    position: absolute;
    width: 129px;
    height: 129px;
    transition: all 0.25s;
}

.inner-tile {
    color: white;
    background-color: #33B5FF;
    animation-duration: 0.25s;
    animation-timing-function:ease-in;
}
/* ********** */

/*MESSAGES*/

@keyframes fadeIn {
    0% {opacity: 0%;}
    100% {opacity: 90%;}
}

.win-message, .lose-message {
    position: absolute;
    font-weight: bold;
    width: 528px;
    height: 532px;
    opacity: 0%;
    font-size: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.win-message {
    color: black;
    background-color: gold;
}

.lose-message {
    color: white;
    background-color: black;
    text-align: center;
}

.fade-in-animation {
    opacity: 90%;
    animation-name: fadeIn;
    animation-duration: 2s;
}
/* ********** */