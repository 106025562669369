/* MAIN CONTAINERS */
.total-container {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}

.row-1, .row-2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
/* ********** */

/* DASHBOARD */
.dashboard {
    background-color: black;
    border: 8px outset white;
    width: 540px;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.score-window, .board-size, .snake-speed {
    font-size: 20px;
    font-weight: bold;
    color: black
 }

 .board-change, .speed-change {
    font-size: 20px;
    background-color: white;
    color: black;
    border: 4px solid black;
 }

 #play-button {
    font-size: 20px;
    border: 4px solid black;
    border-top: none;
    border-left: none;
    width: 150px;
    height: 50px;
    font-weight: bold;
 }
/* ********** */

/* GAME CONTAINER */
.game-container-main {
    display: flex;
    flex-wrap: wrap;
    width: 532px;
    height: 532px;
    border: 4px solid black;
    border-top: none;
    border-right: none;
    background-color: white; 
}

.empty-space {
    background-color: white;
}

.snake-block {
    display: inline-block;
    background-color: white;
    width: 30px;
    height: 30px;
    transition-timing-function: linear
}

.apple {
    background-color: red;
}
/* ********** */

/* COLOR MENU */
.colors {
    width: 154px;
    height: 532px;
    border: 4px solid black;
    border-top: none;
    color: black;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: white;
}

.color {
    width: 80px; 
    height: 40px;
}
/* ********** */